.header{
    text-align: center;
    font: normal normal 700 30px/29px Lato;
    letter-spacing: 0px;
    font-family: 'Lato', sans-serif;
    color: #282D2A;
    opacity: 1;
}

.boxBorder{
    background: white 0% 0% no-repeat padding-box;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    min-height: 200px;
    margin-top: 50px;
    padding: 0px;
}

.boxBorder h4, h6{
    color: #5A727A;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}

.requestOTP{
    width: 100%;
    height: 50px;
    background-color: #7BA345;
    border-radius: 10px;
    border: none;
    outline: none;
    color: white;
    margin-top: 1.5vh;
    font-size: 22px;
    font-weight: 700;
}

.step_1_price_summary{
    background-color: #3C4096;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
}

.twoButtonContainer{
    width: 460px;
    height: auto;
    border: 1px solid #00000030;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
}

.step_1_price_summary_sub{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 460px;
  
}

.headerContainer{
    background-image: url(../../assets//images//header-pattern.png);
    background-size: auto auto;
    background-repeat: no-repeat;
    height: 70px;
    padding-top: 10px;
    padding-left: 20px;
}

.step_2_info{
    max-width: 460px;
    width: 100%;
    min-height: 300px;
    background-color: #3C4096;
    border-radius: 10px;
    flex-direction: column;
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: center;
    height: 100%;
}

.confirm_purchase{
    width: 100%;
    border-radius: 10px;
    height: 50px;
    border: none;
    font-size: 24px;
    font-weight: 700;
    outline: none;
    background-color: #FFD32B;
    color: #fff;
}

.step_2_info_container{
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 10px;
    min-height: 200px;
}

.coverOptions{
    width: 100%;
    height: auto;
    min-height: 100px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    border-color: #3C409650;
    border-width: 1px;
    border-style: solid;
}

.options{
    width: 100%;
    min-height: 60px;
    height: 100%;
    border-color: #3C4096;
    background-color: #3C409620;
    border-radius: 10px;
    display: flex;
    padding: 10px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

.optionsActive{
    width: 100%;
    min-height: 60px;
    height: 100%;
    border-color: #3C4096;
    background-color: #3C4096;
    border-radius: 10px;
    display: flex;
    padding: 10px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.optionsActive p {
    margin-bottom: 0px !important;
    margin-left: 20px;
    color: #FFD32B !important;
}

.options p {
    margin-bottom: 0px !important;
    margin-left: 20px;
}

.optionsActive1{
    width: 100%;
    /* min-height: 60px; */
    /* height: 100%; */
    border-color: #3C4096;
    background-color: #3C4096;
    border-radius: 10px;
    display: flex;
    padding: 8px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.optionsActive1 p {
    margin-bottom: 0px !important;
    margin-left: 20px;
    color: #FFD32B !important;
}
.options1{
    width: 100%;
    /* min-height: 60px; */
    /* height: 100%; */
    border-color: #3C4096;
    /* background-color: #3C409620; */
    background-color: #D8D9EA;
    border-radius: 10px;
    display: flex;
    padding: 8px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.options1 p {
    margin-bottom: 0px !important;
    margin-left: 20px;
}

@media screen and (max-width: 768px) {
    .boxBorder{
        padding: 0px;
        background-color: #EAECF7;
        border: none;
        height: 100vh;
        margin-top: 0px !important;
    }

    .twoButtonContainer{
        width: 90%;
    }


    .coverOptions{
        margin: 20px;
        width: 90% !important;
        padding: 15px !important;
    }

    .form-container{
        background-color: #EAECF7 !important;
        padding: 0px !important;
    }

    .step_1_price_summary_sub{
        width: 300px;
    }
}
