body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.file-upload{
	margin-top: 0.5rem;
}

.file-upload .file-upload-select {
	display: block;
	color: #dbdbdb;
	cursor: pointer;
	text-align: left;
	/* background: #1a242f; */
	overflow: hidden;
	position: relative;
	border-radius: 6px;
	margin-top: 10px;
	border: 1px solid #a5a3a3
}

.file-upload .file-upload-select .file-select-button {
  background: #3c4096;
  padding: 10px;
  display: inline-block;
  color: white;
}

.file-upload .file-upload-select .file-select-name {
	display: inline-block;
	padding: 10px;
	color: rgb(0, 0, 0)
}

.file-upload .file-upload-select:hover .file-select-button {
	background: #324759;
	color: #ffffff;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}

.file-upload .file-upload-select input[type="file"] {
	display: none;
}