/* <------ Nav links Override styles ------> */

.container_main {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 1525px !important;
  overflow-x: hidden !important;
}
.white_formbox {
  background-color: #8a8ab8;
  border-radius: 10px;
}
.white_formbox input {
  border-radius: 5px;
  outline: none;
  border: 2px solid #ffff;
  width: 200px;
  margin-top: 10px;
}
.nav-link.active {
  color: #dd302a !important;
}

p {
  margin-bottom: 1rem;
}

button {
  border: none;
}
footer_exclude input,
select {
  text-align: left;
  font: normal normal bold 16px/29px Lato !important;
  letter-spacing: 0px;
  color: #5a727a;
}

.whiteoverlay_card {
  background-color: #59599A;
  justify-content: center;
  border: 1px solid #707070;
  border-radius: 6px;
  opacity: 1;
  color: white;
  /* width: 315px; */
  width:331px;
  height: 61%;
  padding-left: 17px;
  padding-right: 17px;
}

input::placeholder {
  opacity: 0.5 !important;
}

.home-container {
  background-size: 100%;
  background-color: #da291c;
}

.nav-link {
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 1rem;
}

.nav-link:hover {
  color: var(--primary) !important;
}

.error-input {
  border: 1px solid rgb(218 41 28 / 25%) !important;
  box-shadow: 0 0 0 0.25rem rgb(218 41 28 / 25%) !important;
}

.errors {
  color: #da291c;
  font-size: 0.8rem;
}

/* div,span, a{
      color: rgb(80, 97, 106) !important;
  } */

/* <------- Divider ------> */

.divider {
  background-color: #d0d0d0;
  height: 2px;
}

/* <----- Form label override style ----->  */

.form-label {
  color: #2f2e80  !important;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
}
.dashed {
  padding-left: 10px !important;
}

ul.dashed {
  list-style-type: '-';
}

ul.dashed > li {
  padding: 0px 0px 0px 0px;
  margin-left: 10px;
}

.t_c_text {
  color: #da291c;
  font-weight: 600;
}

/* <----- Stepper override style ----->  */

/* .MuiStepIcon-root.Mui-completed,
  .MuiStepIcon-root.Mui-active,
  .MuiStepLabel-label.Mui-completed {
    color: #da291c !important;
  }
  
  .MuiStepLabel-label {
    font: normal normal normal 14px/26px Lato !important;
    letter-spacing: 0.11px;
    color: #5a727a;
    opacity: 1;
  } */

.textarea-label {
  color: #5a727a;
  height: 30px !important;
  margin-top: 10px;
}

.MuiFormControlLabel-label {
  text-align: left;
  font: normal normal normal 16px/29px Open sans !important;
  letter-spacing: 0px;
  font-family: 'Open sans' !important;
  color: #000000;
  opacity: 0.8;
}

/* <------- Header Styles --------> */

.header__title {
  text-align: left;
  font: normal normal bold 16px/25px Lato;
  letter-spacing: 0px;
  color: #da291c !important;
  line-height: 30px !important;
  opacity: 1;
}
.header__title {
  text-align: left;
  font: normal normal bold 16px/25px Lato;
  letter-spacing: 0px;
  color: #da291c !important;
  opacity: 1;
}

.header__sub {
  text-align: left;
  font: normal normal 800 16px/29px Lato;
  letter-spacing: 0px;
  color: #6e6f6c;
  opacity: 1;
}

.description_placeholders {
  text-align: left;
  font: normal normal normal 16px/24px Lato;
  letter-spacing: 0px;
  color: #6e6f6c;
  opacity: 1;
}

.list > li {
  text-align: left;
  font: normal normal bold 16px/26px Lato;
  letter-spacing: 0.16px;
  color: #5a727a;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 3em;
  border-width: 2px;
  height: 3em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.5em solid rgb(218, 41, 28);
  border-right: 0.5em solid rgb(218, 41, 28);
  border-bottom: 0.5em solid rgb(218, 41, 28);
  border-left: 0.5em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.gif-loader {
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.gif-loader-full {
  width: 100%;
  height: 90vh;
  margin: auto;
  align-items: center;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

/* --------Homepage----------------------- */

.homepage-banner-pattern {
  background-color: #2f2e80;
  background-image: url(../assets/images/header-pattern.png);
}

.homepage1__heading1 {
  line-height: 1.2;
  padding-right: 20px;
  color: white;
  font-size: 58px;
  font-weight: bold;
}

.homepage1__heading2 {
  color: white;
  font-size: 14px;
}

.homepage__main__text {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.secondary-container {
  background-color: #e5e5f5;
}

.card__header {
  color: white;
  font-size: var(--font-size-h2);
  font-weight: 800;
  display: flex;
  align-items: center;
  /* width: 70% !important; */
}

.card__header__container {
  background: var(--secondary);
  border-radius: 10px;
  align-items: center;
  padding: 5px;
  /* margin-right: 30px; */
}

.card_header_img {
  width: 100px;
  padding: 5px 10px;
  /* border-radius: 10px;
    background: white; */
  text-align: center;
}

.card__homepage {
  width: 100% !important;
  border-radius: 10px;
}

.card__list__header {
  color: var(--primary);
  font-weight: 700;
}

.card__list {
  color: var(--font-secondary);
  font-size: 14px;
  border-bottom: 1px solid #d2d1d1;
  padding-bottom: 20px;
  padding-left: 15px;
}

.card__list2 {
  padding-bottom: 0px;
}

.card__list2 > li {
  margin-bottom: 20px;
}

.card__list > li > span:first-child {
  font-weight: 600;
}

.card__list > li > div:first-child {
  font-weight: 600;
}

.card__footer {
  color: var(--font-secondary);
}

.card__footer > span:first-child {
  font-weight: 700;
}

.card__footer > span:last-child {
  font-weight: 700;
  color: var(--font-primary);
  cursor: pointer;
}

.card__footer > span:last-child:hover {
  color: var(--primary);
}

.card__footer__bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--primary);
}

.amount {
  font-size: 1.4rem;
}

.amount > span:last-child {
  color: var(--primary);
  font-weight: 800;
}

.card__avatar__text > div:first-child {
  font-size: 14px;
  font-weight: 700;
  color: var(--secondary);
}

.card__avatar__img {
  width: 170px;
}

.Mui-error {
  border: #da291c !important;
}

.card__avatar__text > div:last-child {
  font-size: 14px;
  color: var(--secondary);
}

.card__list2__un {
  list-style-type: none;
  border: none;
}
.bullet {
  text-decoration: dotted;
}

.card__footer2 {
  color: var(--font-secondary);
  text-align: left;
}

.card__footer2 > span {
  font-weight: 700;
  color: var(--font-primary);
  cursor: pointer;
}

.card__footer2 > span:last-child:hover {
  color: var(--primary);
}

.font-weight-bold {
  font-weight: bold;
}

/* -----------------Content------------ */

.content {
  color: var(--secondary);
}

/* ------------------Breadcrumb------------------ */
.breadcrumbs {
  padding-top: 15px;
}

.breadcrumbs > a {
  color: var(--font-secondary);
  text-decoration: none;
}

.breadcrumbs > a > svg {
  font-size: 14px;
}

.gif-loader {
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.boxBorder {
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  min-height: 200px;
  margin-top: 50px;
  padding: 30px;
}

.contact_form_input input,
textarea {
  height: 50px;
}

.contact_form_input label {
  font-weight: 700 !important;
  color: #5a727a;
}

.boxBorder h4,
h6 {
  color: #5a727a;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
}

.gif-loader-fullscreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  margin: auto;
  align-items: center;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.homepage_dob_container{
  /* width: 350px; */
  /* height: 200px; */
  height: 354px;
  width: 277px;
  flex-direction: column;
  display: flex;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  position: relative;
  margin-bottom: 20px;
  /* background-color: #2f2e8070; */
  background-color: #8A8AB8;
}

.homepage_dob_container input{
  border-radius: 5px;
  outline: none;
  /* border: 2px solid #2f2e80; */
  border: 2px solid #ffff;
  width: 200px;
  margin-top: 10px;
}

.homepage_dob_container button{
position: absolute;
bottom: -25px;
border-radius: 20px;
padding: 10px;
font-weight: 700;
color: #2f2e80;
background-color: #fbcf2c;
width: 120px;
}

.lds-dual-ring {
  display: inline-block;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 24px;
  height: 24px;
  margin: 5px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.footer_logo_lg {
  width: 160px;
  margin-bottom: 5px;
  transform: translateX(-5px);
}

/* Keyframe animation for loader */
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* -------------Footer---------------- */
.footer_logo {
  max-width: 120px;
  margin-top: 10px;
}

/* -----------------contact-------------- */
.contact_submit {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #da291c !important;
  color: white;
  font-size: 18px;
  width: 150px;
  font-weight: 900;
  box-shadow: 2px 2px 8px #6d6565;
  margin-top: 20px;
}

.main__img {
  width: 50%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.stats-img {
  width: 100%;
}

.app__header__two__sub {
  color: #5a727a;
}

/* Media query for mobile screens */
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  /* changing homepage image width */
  .main__img {
    width: 160px;
  }

  /* Changing width of card in homepage */
  .card__homepage {
    width: 100% !important;
  }
  /* Changing width of card header */
  /* 
    .card__header {
      width: 100% !important;
    }
    */

  .home-container {
    background-size: 400%;
  }

  /* Font-size changed selecting the first row */
  .homepage__main__text > .row:first-child {
    font-size: 26px;
    text-align: center;
    font-weight: 900;
  }

  .homepage1__heading2 {
    text-align: center;
  }

  .app__header__two {
    text-align: center;
    font-size: 26px;
  }

  .app__header__two__sub {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  .quote_button {
    border-radius: 20px;
    margin-bottom: -5%;
    margin-top: 10%;
  }
  .higlight_text {
    color: #fbcf2c;
  }
  .higlighted_link a {
    color: #fbcf2c;
    font-size: 16px;
  }
  /* displaying footer on mobile screen */
  .card__footer__bottom {
    display: block;
  }

  /* Changing the width of the card footer */
  .card__footer__bottom > div:last-child {
    width: auto;
  }

  /* Changing card image */
  .card__avatar__img {
    width: 130px;
  }

  .card__list__header {
    text-align: center;
  }

  .card__list2__un {
    font-size: 16px;
  }

  .boxBorder {
    padding: 10px;
  }
  /* changing font size of amount */
  .amount {
    font-size: 1rem;
    margin-right: 10px;
  }

  .card__footer2 {
    text-align: center;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  /* Changing card image */
  .card__avatar__img {
    width: 170px;
  }
}
